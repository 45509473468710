import { useEffect, useRef } from 'react';

import './PendingTxModal.scss';

interface IModalProps {
  show: boolean;
}

function PendingTxModal({ show }: IModalProps) {
  const ref = useRef<any>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.style.display = show ? 'block' : 'none';
  }, [show]);

  return (
    <div ref={ref} className='tx-modal'>
      <div className='tx-modal-content'>
        <p>Pending Transaction</p>
        <p>Please wait...</p>
      </div>
    </div>
  );
}

export default PendingTxModal;
