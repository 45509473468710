import { useContext } from 'react';

import { ConnectionContext } from './../../context/Connection';

function Connection() {
  const { connect, connecting } = useContext(ConnectionContext);

  function onConnect() {
    connect().catch((err) => console.log(err));
  }

    return (

        <div className="wrap text-content center">
            <h1>GHOST NFT Collection</h1>
	    {connecting ? (
		<p>Connecting...</p>
	    ) : (
		<p>
		    <button data-text="Connect Wallet" className="btn" onClick={onConnect}>Connect Wallet</button>
		</p>
	    )}
	</div>
    );
}

export default Connection;
