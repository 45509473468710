import { useContext, useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';

import * as blockchainService from './../../services/blockchainService';

import { ConnectionContext } from '../../context/Connection';
import Connection from './../Connection/Connection';

import { Ghost } from '../../types';
import { CHAIN_ID, CONTRACT_ADDRESS } from '../../constants';
import { CONTRACT_ABI } from '../../constants/abis';
import './Mint.scss';
import PendingTxModal from './PendingTxModal/PendingTxModal';

interface ISaleState {
    saleStart: number;
    price: BigNumber;
    minted: number;
    edition: number;
}

function Mint() {
    const { wallet, provider, setChain } = useContext(ConnectionContext);
    const [contract, setContract] = useState<Ghost | null>(null);
    const [saleState, setSaleState] = useState<ISaleState | null>(null);
    const [inputAmount, setInputAmount] = useState<number>(1);
    const [totalPrice, setTotalPrice] = useState<BigNumber | null>(null);
    const [maxQuantity, setMaxQuantity] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const [pendingTxModal, setPendingTxModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [changeNetwork, setChangeNetwork] = useState(false);
    const [tokenId, setTokenId] = useState(0);
    const [loading, setLoading] = useState(false);

    const nftNames=[
        'GHOST',
        'LIGHT AND DUST',
        'BOTTLENECK',
        'ANTIC STRIKE',
        'BREAD AND SALT',
        'OLIGARCHS',
        'FAKE NEWS',
        'THAT’S ALL WE NEED',
        'GHOST BLESS YOU',
        'WILL-O’-THE-WISP',
        'BIRD TWIST',
        'PEACE AND HELL'
    ];

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string)
        }) as any;
        setTokenId(parseInt(params.tokenId)  || 0);
    }, []);

    useEffect(() => {
        handleSaleData();
    }, [contract, tokenId]);

    useEffect(() => {
        if (!provider || !wallet?.accounts[0]?.address) {
            setContract(null);
            return;
        }

        let update = true;
        provider.getNetwork().then((network) => {
            if (!update) {
                return;
            }

            if (network.chainId.toString() !== CHAIN_ID) {
                setChangeNetwork(true);
                return;
            } else {
                setChangeNetwork(false);
            }

            setContract(
                blockchainService.getContract(CONTRACT_ADDRESS, CONTRACT_ABI, provider, wallet?.accounts[0].address!) as Ghost
            );
        });

        return () => {
            update = false;
        };
    }, [provider, wallet?.accounts]);

    useEffect(() => {
        if (!saleState) {
            setTotalPrice(null);

            return;
        }

        setTotalPrice(saleState?.price.mul(inputAmount));
    }, [inputAmount, saleState]);

    useEffect(() => {
        if (!saleState) {
            setMaxQuantity(0);

            return;
        }

        setMaxQuantity(saleState.edition - saleState.minted);
    }, [saleState]);

    async function handleSaleData() {
        if (!contract || tokenId === null) {
            setSaleState(null)
            return;
        }

        contract
            .mintConditions(tokenId)
            .then(({ saleStartMinusOne, editionPlusOne, minted, price }) => {
                setSaleState({
                    saleStart: saleStartMinusOne + 1,
                    minted,
                    edition: editionPlusOne - 1,
                    price
                });
            })
            .catch((err) => {
                console.log(err);
                setSaleState(null);
            });
    }

    function mint() {
        const account = wallet?.accounts[0]?.address;
        if (!saleState || !totalPrice || !contract || !account || tokenId === null) {
            return;
        }

        setLoading(true);
        contract!
            .mint(account, tokenId, inputAmount, { value: totalPrice.toString() })
            .then(async (tx) => {
                setPendingTxModal(true);

                return tx.wait().then((_txMined) => {
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShowSuccess(false);
                    }, 8000);
                    setInputAmount(1);
                });
            })
            .catch((err) => {
                console.log(err.reason);
                showError(err.reason);
            })
            .finally(() => {
                setPendingTxModal(false);
                handleSaleData().then(() => {
                    setLoading(false);
                });
            });
    }

    function showError(message: string) {
        setError(message ? message.slice(0, 256) + '...' : 'Something went wrong! Please reload!');
        setTimeout(() => {
            setError(null);
        }, 6000);
    }

    function decreaseAmount() {
        if (inputAmount > 1) {
            setInputAmount(inputAmount - 1);
        }
    }

    function increaseAmount() {
        if (inputAmount < maxQuantity) {
            setInputAmount(inputAmount + 1);
        }
    }

    function setChainHandler() {
        // In case of localhost setup
        const rightChainId = CHAIN_ID === '1337' ? 1337 : '0x' + Number(CHAIN_ID).toString(16);
        setChain({ chainId: rightChainId });
    }

    return (
            <>
            {changeNetwork ? (
                    <div className="wrap text-content center">
                    <p>
                    <button onClick={setChainHandler} className='btn'>
                    Switch Network
                </button>
                    </p>
                    </div>
            ) : loading ? (
                    <>
                    <div className="wrap text-content center">
                    <p>Loading...</p>
                    <PendingTxModal show={pendingTxModal} />
                    </div>
                    </>
            ) : saleState ? (
                    <>
                    <div className="wrap text-content center">
                    <h1>GHOST NFT Collection</h1>
                    {error && (
                            <>
                            <h3>Error:</h3>
                            <p>{error}</p>
                            </>
                    )}
                {showSuccess && (
                        <>
                        <h3>Congratulations! You are owner of a NFT.</h3>
                        </>
                )}
                {typeof tokenId === 'number' ? (
                        <h2>{nftNames[tokenId]}</h2>
                ) : '' }
                </div>
                    <div className="wrap">
                    <dl>
                    <div className="prop-wrap">
                    <dt>Total Supply</dt><dd>{saleState.edition} NFTs</dd>
                    </div>
                    <div className="prop-wrap">
                    <dt>Already Minted</dt><dd>{saleState.minted}</dd>
                    </div>
                    <div className="prop-wrap">
                    <dt>Price Per NFT</dt><dd>ETH {saleState?.price ? ethers.utils.formatEther(saleState?.price).toString() : 'n/a'}</dd>
                    </div>
                    </dl>
                    </div>
                    <div className="wrap text-content center">
                    <h2>Select a quantity</h2>
                    <p className="qty">
                    <button className="btn" onClick={decreaseAmount}>-</button>
                    <input type="text" readOnly value={inputAmount} />
                    <button className="btn" onClick={increaseAmount}>+</button>
                    </p>
                    {/* <p>{maxQuantity} Max</p> */}
                    <p>

                    </p>

                    <p>Total Price: ETH {totalPrice ? ethers.utils.formatEther(totalPrice).toString() + ' + Gas' : 'n/a'}
                </p>
                    <button
                data-text="Mint"
                onClick={mint}
                className='btn big'
                disabled={
                    !saleState ||
                        !totalPrice ||
                        saleState.minted === saleState.edition ||
                        Date.now() / 1000 < saleState.saleStart
                }
                    >Mint</button>

                    {saleState && Date.now() / 1000 < saleState.saleStart && <p className='error'>Sale NOT started!</p>}
                </div>
                    </>
            ) : (
                    <Connection />
            )}
        </>
    );
}

export default Mint;
