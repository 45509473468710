import { useContext } from 'react';

import { ConnectionContext } from '../../context/Connection';
import { formatAddress } from '../../services/blockchainService';
import logo from '../../images/GHOST.png'; // with import

import './Header.scss';

function Header() {
    const { wallet, disconnect } = useContext(ConnectionContext);

    function onDisconnect() {
        disconnect(wallet!);
        window.localStorage.removeItem('connectedWallets');
    }

    return (
        <div className='pageHeader'>
            <div className="wrap">
                <h1>
                    <img src={logo} alt="GHOST" />
                </h1>
                {wallet?.accounts[0] && (
                    <div className="wallet">
                        <p>Account: {formatAddress(wallet?.accounts[0].address, 6, 5)}</p>
                        <button data-text="Disconnect" className="btn" onClick={onDisconnect}>Disconnect</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Header;
