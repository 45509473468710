if (!process.env.REACT_APP_CONTRACT_ADDRESS) {
  throw new Error(`'REACT_APP_CONTRACT_ADDRESS' env variable is not provided!`);
}
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

if (!process.env.REACT_APP_PROVIDER_API_KEY) {
  throw new Error(`'REACT_APP_PROVIDER_API_KEY' env variable is not provided!`);
}

export const PROVIDER_API_KEY = process.env.REACT_APP_PROVIDER_API_KEY;

if (!process.env.REACT_APP_CHAIN_ID) {
  throw new Error(`'REACT_APP_CHAIN_ID' env variable is not provided!`);
}
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
